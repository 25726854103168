import { PeopleCardProps } from "@/components/ui/PeopleCard";
import { PricingCardProps } from "@/components/ui/PricingCard";
import { ReviewCardProps } from "@/components/ui/ReviewCard";
import XSVG from "@/components/ui/X";
import { Instagram, Linkedin, Mic, Youtube } from "lucide-react";

interface Config {
  siteTitle: string;
  MenuLinks: Array<{
    href: string;
    label: string;
    langKey: string;
  }>;
  fondator: PeopleCardProps;
  comedians: PeopleCardProps[];
  pricingCard: PricingCardProps[];
  reviews: ReviewCardProps[];
}

const config: Config = {
  siteTitle: "S.A.C Studio",
  MenuLinks: [
    { href: "/#hero", label: "Accueil", langKey: "home" },
    { href: "/#combat", label: "Notre combat", langKey: "fight" },
    { href: "https://www.sacstudio.be/comedien", label: "Nos Comédiens", langKey: "team" },
    { href: "/#tarifs", label: "Nos tarifs", langKey: "rate" },
    { href: "/#contact", label: "Contact", langKey: "contact" },
  ],
  fondator: {
    nomPrenom: "Ricardo Rhodes (Akira)",
    posteFr: "Fondateur",
    posteEn: "Founder",
    image: "ricardo",
    flag: ["fr", "en"],
    audios: {
      fr: [],
      en: [],
      jp:[],
    },
    iconsFr: [
      {
        icon: <Instagram width={20} height={20} />,
        lien: "https://www.instagram.com/sac.studio.officiel/",
      },
      {
        icon: <Linkedin width={20} height={20} />,
        lien: "https://linkedin.com",
      },
    ],
  },
  comedians: [
    {
      "nomPrenom": "Leonie Schliesing",
      "posteFr": "Comédien",
      "posteEn": "Actor",
      "image": "leonie",
      "flag": ["fr","en","de"],
      "audios": {
        "fr": [
          { "src": "/audios/leonie/ferrero.mp3", "title": "Créateurs de magie - Ferrero Rocher - Publicité" },
          { "src": "/audios/leonie/KIABI.mp3", "title": "La mode à petit prix - KIABI - Publicité" },
          { "src": "/audios/leonie/Aurore Cassel - Cyberpunk 2077 Phantom Liberty - Jeux Video.mp3", "title": "Aurore Cassel - Cyberpunk 2077 Phantom Liberty - Jeux Vidéo" }
        ],
        "en": [
          { "src": "/audios/leonie/Aurore Cassel - Cyberpunk 2077 Phantom Liberty - Video Game.mp3", "title": "Aurore Cassel - Cyberpunk 2077 Phantom Liberty - Video Game" },
          { "src": "/audios/leonie/The new galaxy note 9 - Samsung - commercial.mp3", "title": "The New Galaxy Note 9 - Samsung - Commercial" },
          { "src": "/audios/leonie/Ovulation test kit - Clearblue - Commercial.mp3", "title": "Ovulation Test Kit - Clearblue - Commercial" },
          { "src": "/audios/leonie/The Greek Islands - Travel Blog - Documentary.mp3", "title": "The Greek Islands - Travel Blog - Documentary" },
          { "src": "/audios/leonie/The Neighbourhood Hotel - Hotel Indigo - commercial.mp3", "title": "The Neighbourhood Hotel - Hotel Indigo - Commercial" },
          { "src": "/audios/leonie/Moon to Mars Project - European Space Agency - Corporate Narration.mp3", "title": "Moon to Mars Project - European Space Agency - Corporate Narration" },
          { "src": "/audios/leonie/Toka Sinricsdottir - Assassins Creed Valhalla The Siege Of Paris - Video Game.mp3", "title": "Toka Sinricsdottir - Assassin's Creed Valhalla The Siege Of Paris - Video Game" },
          { "src": "/audios/leonie/Welcome to Brightbox - Employee Onboarding - E-learning.mp3", "title": "Welcome to Brightbox - Employee Onboarding - E-learning" },
          { "src": "/audios/leonie/Quinn - Glam Pursuit - Video Game.mp3", "title": "Quinn - Glam Pursuit - Video Game" }
        ],
        "de": [
          { "src": "/audios/leonie/Freddie Mercury - Queen - Dokumentation.mp3", "title": "Freddie Mercury - Queen - Dokumentation" },
          { "src": "/audios/leonie/Shopify.mp3", "title": "Geschäftsidee - Shopify - Werbung" },
          { "src": "/audios/leonie/Business Planspiel - MARGA - Imagefilm.mp3", "title": "Business Planspiel - MARGA - Imagefilm" },
          { "src": "/audios/leonie/Haftpflichtversicherung - Allianz - Werbung.mp3", "title": "Haftpflichtversicherung - Allianz - Werbung" },
          { "src": "/audios/leonie/Playstation 5 - SONY - Werbung.mp3", "title": "PlayStation 5 - SONY - Werbung" }
        ],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },  
    {
      nomPrenom: "Andrés Macías Hernandez",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "andres",
      flag: ["en", "es", "it"],
      audios: {
        es: [
          { src: "../audios/andres/Andrés Macías - Colombia, mi abuelo y yo - Audiobook.mp3", title: "Colombia, mi abuelo y yo - Audiobook" },
          { src: "../audios/andres/Andrés Macías - Sanofi Healthcare Latin America - Corporate.mp3", title: "Sanofi Healthcare Latin America - Corporate" },
          { src: "../audios/andres/Andrés Macías - Discovery Channel - Promo.mp3", title: "Discovery Channel - Promo" },
          { src: "../audios/andres/Andrés Macías - ESPN - Promo.mp3", title: "ESPN - Promo" },
          { src: "../audios/andres/Andrés Macías - Historia Freak del Cine II - Audiobook.mp3", title: "Historia Freak del Cine II - Audiobook" },
          { src: "../audios/andres/Andrés Macías - Los amigos del hombre - Audiobook.mp3", title: "Los amigos del hombre - Audiobook" },
          { src: "../audios/andres/Andrés Macías - Comida de Verdad - Audiobook.mp3", title: "Comida de Verdad - Audiobook" },
        ],
        it: [
          { src: "../audios/andres/Andrés Macías - Descrizione audio Museo - Audio Description.mp3", title: "Descrizione audio Museo - Audio Description" },
          { src: "../audios/andres/Andrés Macías - Egitto - Corporate.mp3", title: "Egitto - Corporate" },
        ],
        en: [
          { src: "../audios/andres/Andrés Macías - In-N-Out - Commercial.mp3", title: "In-N-Out - Commercial" },
          { src: "../audios/andres/Andrés Macías - Mercedes Benz - Commercial.mp3", title: "Mercedes Benz - Commercial" },
          { src: "../audios/andres/Andrés Macías - Nickelodeon - Promo.mp3", title: "Nickelodeon - Promo" },
          { src: "../audios/andres/Andrés Macías - Transformers Hasbro - Commercial.mp3", title: "Transformers Hasbro - Commercial" },
        ],
        fr: []
      },
      iconsFr: [],
      iconsEn: [],
    },    
    {
      nomPrenom: "Eriko Hayami",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "eriko",
      flag: ["jp"],
      audios: {
        jp: [
          { src:"../audios/eriko/–‚Ź—.wav", title: "–‚Ź—" },
          { src:"../audios/eriko/Aoudiobok_昔話.wav", title: "Aoudiobok 昔話" },
          { src:"../audios/eriko/e-learning心理学.wav", title: "e-learning 心理学" },
          { src:"../audios/eriko/IVRƒ{ƒCƒXƒfƒ.wav", title: "IVR" },
          { src:"../audios/eriko/エルフ.wav", title: "エルフ" },
          { src:"../audios/eriko/キュートな妖精.wav", title: "キュートな妖精" },
          { src:"../audios/eriko/シルバーラップトップ_YouTube広告.mp3", title: "シルバーラップトップ_YouTube広告" },
          { src:"../audios/eriko/ドキュメンタリー.mp3", title: "ドキュメンタリー" },
          { src:"../audios/eriko/不動産会社ウェブ広告.wav", title: "不動産会社ウェブ広告" },
          { src:"../audios/eriko/企業紹介.wav", title: "企業紹介" },
        ],
        fr : [

        ],
        en: [
        ],
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Alex Morrison (Neckhaos)",
      posteFr: "Compositeur & Comédien",
      posteEn: "Screenwriter & Actor",
      image: "alex",
      flag: ["fr", "en"],
      audios: {
        fr: [
          { src:"../audios/alex/Archiford_Clyde_Vanilla_EP4.mp3", title: "Archiford - Clyde Vanilla - Épioosde 4 - Fiction Audio" },
          { src:"../audios/alex/Disco_Jesus_Clyde_Vanilla_EP7.mp3", title: "Disco Jesus - Clyde Vanilla - Épioosde 7 - Fiction Audio" },
          { src:"../audios/alex/Fitzgerald_S3_EP4_Dahut.mp3", title: "Dabut - Les Contes de Fitzgerald - S3 Épiosde 4 - Fiction Audio" },
          { src:"../audios/alex/Pompiste_Les_Pourfendeurs_EP5.mp3", title: "Pompiste - Les Pourfendeurs - Épisode 5 - FIction Audio" },
          { src:"../audios/alex/Pompiste_2_Pourfendeurs_EP10.mp3", title: "Pompiste - Les Pourfendeurs - Épisode 10 - FIction Audio" },
          { src:"../audios/alex/Présentateur_EP1_TFX.mp3", title: "Présentateur - Trauma Fantasy X - Épisode 1 -  Fiction Audio" },
          { src:"../audios/alex/Raphael_Believe_in_yourself_Spot_3.mp3", title: "Raphael - Believe in yourself - Épisode 3 - Fiction Audio" },
          { src:"../audios/alex/Spot_Pub_pourfendeur.mp3", title: "Faux Spot Pub - Les Pourfendeurs - Fiction Audio" },
          { src:"../audios/alex/Stauron_TFX_EP2.mp3", title: "Stauron - Trauma Fantasy X - Épisode 2 -  Fiction Audio" },
          { src:"../audios/alex/PapÇ_Clyde_Vanilla_Ep6.mp3", title: "Le Papé - Clyde Vanilla - Épisode 6 - Fiction Audio" },
          { src:"../audios/alex/Glagnork_EP19_Nick.mp3", title: "Nick - Le Monde Pitoyable De Glagnork - Épisode 19 - Fiction Audio" },

        ],
        en: [
          { src:"../audios/alex/Ricardo-Rhodes-S.A.C-Studio-Video-presentation.mp3", title: "Ricardo Rhodes - S.A.C studio - Video presentation"},
          { src:"../audios/alex/IVR-_-SVI-S.A.C-Studio-_EN_.mp3", title: "IVR / SVI - S.A.C Studio"},
          { src:"../audios/alex/Jim_&_Michael_Glagnork_EP21.mp3", title: "Jim & Michael - Le Monde Pitoyable De Glagnork - Épisode 21 - Fiction Audio" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Eva Aleman",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "eva",
      flag: ["fr","en", "es"],
      audios: {
        fr: [
          { src:"../audios/eva/Eva-Aleman_La_Boîte_e-book_Français.mp3", title: "La Boîte e-book" },
          { src:"../audios/eva/Eva-Aleman_Louvain-Cooperation_Corporate_Français.mp3", title: "Louvain Cooperation Corporate" },
          { src:"../audios/eva/Eva-Aleman_Publicité_Radio_Français.wav", title: "Publicité Radio" },
          { src:"../audios/eva/Eva-Aleman_Publicité_TV_Français.mp3", title: "Publicité TV" },
          { src:"../audios/eva/Eva-Aleman-Pink_Gellac_UGC_Français.wav", title: "Pink Gellac UGC" },
        ],
        en: [
          { src:"../audios/eva/Eva-Aleman_Cockerill_Corporate_Anglais.mp3", title: "Cockerill Corporate" },
          { src:"../audios/eva/Eva-Aleman_European_Parliament_Corporate_Anglais.mp3", title: "European Parliament Corporate" },
          { src:"../audios/eva/Eva-Aleman_Samsonite_Publicité_online_Anglais.mp3", title: "Samsonite Publicité online" },
        ],
        es: [
          { src:"../audios/eva/Eva_Aleman_Frenehard_Michaux_Corporate_Espagnol.mp3", title: "Frenehard Michaux Corporate" },
          { src:"../audios/eva/Eva-Aleman_Fantastic_Brussels_Audio_Musee_Espagnol.mp3", title: "Fantastic Brussels Audio Musee" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      "nomPrenom": "Philippe Bassot",
      "posteFr": "Comédien",
      "posteEn": "Actor",
      "image": "philippe",
      "flag": ["fr"],
      "audios": {
        "fr": [
          { "src": "../audios/philippe/Bukowski - Go All The Way - Capsule Audio.mp3", "title": "Bukowski - Go All The Way - Capsule Audio" },
          { "src": "../audios/philippe/Bukowski - Reinvent yourself - Capsule Audio.mp3", "title": "Bukowski - Reinvent Yourself - Capsule Audio" },
          { "src": "../audios/philippe/GOW Radio - BATICO - Publicité.mp3", "title": "GOW Radio - BATICO - Publicité" },
          { "src": "../audios/philippe/GOW Radio - BATICO_FINNOV - Publicité.mp3", "title": "GOW Radio - BATICO FINNOV - Publicité" },
          { "src": "../audios/philippe/Je sais -Slam - Musique.mp3", "title": "Je sais - Slam - Musique" },
          { "src": "../audios/philippe/Je suis un homme - Slam - Musique.mp3", "title": "Je suis un homme - Slam - Musique" },
          { "src": "../audios/philippe/JINGLE Radio B - Raph - Radio.mp3", "title": "JINGLE Radio B - Raph - Radio" },
          { "src": "../audios/philippe/La Plus belle -Slam - Musique.mp3.mp3", "title": "La Plus Belle - Slam - Musique" },
          { "src": "../audios/philippe/Montre-moi -Slam - Musique.mp3.mp3", "title": "Montre-moi - Slam - Musique" },
          { "src": "../audios/philippe/Narrateur - La porte du ciel - Documentaire TV.mp3", "title": "Narrateur - La Porte du Ciel - Documentaire TV" },
          { "src": "../audios/philippe/Narrateur - Notre-Dame de Paris - Parcours Visiteur - Audioguide.wav", "title": "Narrateur - Notre-Dame de Paris - Parcours Visiteur - Audioguide" },
          { "src": "../audios/philippe/Narrateur - Quand je pense à toi tout bas - Capsule Audio.mp3", "title": "Narrateur - Quand je pense à toi tout bas - Capsule Audio" },
          { "src": "../audios/philippe/REF-FR_M_philippe-bassot _demo-2025Q1 - Medley.mp3", "title": "REF-FR - Medley démonstration 2025" }
        ],
        "en": [],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },
    {
      "nomPrenom": "Benoît Carry",
      "posteFr": "Comédien",
      "posteEn": "Actor",
      "image": "benoit",
      "flag": ["fr"],
      "audios": {
        "fr": [
          { "src": "/audios/benoit/9h59 New York 20 ans après - Voice over.mp3", "title": "9h59 New York 20 ans après - Voice over" },
          { "src": "/audios/benoit/Le bénévolat - institutionnel - Voix Off.mp3", "title": "Le bénévolat - Institutionnel - Voix Off" },
          { "src": "/audios/benoit/EMMISSIC - Explainer application - Voix off.mp3", "title": "EMMISSIC - Explainer application - Voix off" },
          { "src": "/audios/benoit/Atelier du maker_ institutionnel_Voix off.mp3", "title": "Atelier du maker - Institutionnel - Voix off" },
          { "src": "/audios/benoit/Tony Hawk 900 - Voice over.mp3", "title": "Tony Hawk 900 - Voice over" },
          { "src": "/audios/benoit/Boxers Bordeaux - Pub radio.mp3", "title": "Boxers Bordeaux - Pub radio" },
          { "src": "/audios/benoit/Anna Llenas - La couleur des émotions - Livre audio jeunesse.mp3", "title": "Anna Llenas - La couleur des émotions - Livre audio jeunesse" },
          { "src": "/audios/benoit/Création de personnages - Moduations vocales.mp3", "title": "Création de personnages - Modulations vocales" },
          { "src": "/audios/benoit/EvaIllouz-Pourquoilamourfaitmal-Livreaudiosociologie.mp3", "title": "Eva Illouz - Pourquoi l'amour fait mal - Livre audio sociologie" },
          { "src": "/audios/benoit/Guyane action logement - pub radio.mp3", "title": "Guyane Action Logement - Pub radio" },
          { "src": "/audios/benoit/Halloween - diffusion interne entreprise.mp3", "title": "Halloween - Diffusion interne entreprise" },
          { "src": "/audios/benoit/HowardAshman-Jesuistonmeilleuramis-Chanson.mp3", "title": "Howard Ashman - Je suis ton meilleur ami - Chanson" },
          { "src": "/audios/benoit/Eckhart Tolle - Le pouvoir du moment présent - Livre audio développement personnel.mp3", "title": "Eckhart Tolle - Le pouvoir du moment présent - Livre audio développement personnel" },
          { "src": "/audios/benoit/La chine vue du ciel - Les rizières en terrasse de Hani - Documentaire.mp3", "title": "La Chine vue du ciel - Les rizières en terrasse de Hani - Documentaire" },
          { "src": "/audios/benoit/R.A Salvatore - La légende de Drizzt Tome 3 - Livre Audio Fiction.mp3", "title": "R.A. Salvatore - La légende de Drizzt Tome 3 - Livre audio fiction" },
          { "src": "/audios/benoit/Tempete de boulettes géantes - Doublage (avec Élise Gamet).mp3", "title": "Tempête de boulettes géantes - Doublage (avec Élise Gamet)" },
          { "src": "/audios/benoit/Leclerc - Pub radio.mp3", "title": "Leclerc - Pub radio" }
        ],
        "en": [],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },    
    {
      nomPrenom: "Alain Chaillot",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "alain",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/alain/AssuranceVie-Meilleurtaux-PubTV.mp3", title: "Assurance Vie - Meilleur taux - Pub TV" },
          { src:"../audios/alain/ChampionsLeague2024-Pubradio.mp3", title: "Champions League 2024 - Pub radio" },
          { src:"../audios/alain/GuadeloupeBillboard-VSMradio.mp3", title: "Guadeloupe Billboard - VSM radio" },
          { src:"../audios/alain/JamesRosone-Auxconfinsdesétoiles-Livreaudio(Audible).mp3", title: "James Rosone - Aux confins des étoiles - Livre audio (Audible)" },
          { src:"../audios/alain/GuillaumeLecointre.mp3", title: "Guillaume Lecointre - L'évolution question d'actualité ? - Livre audio (Audible)" },
          { src:"../audios/alain/JEEP-Pubradio.mp3", title: "JEEP - Pub radio" },
          { src:"../audios/alain/MédiaClinic-Pubradio.mp3", title: "Média Clinic - Pub radio" },
          { src:"../audios/alain/SOPRANO2025-Pubradio.mp3", title: "SOPRANO 2025 - Pub radio" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      "nomPrenom": "Jeremy Dubard",
      "posteFr": "Comédien",
      "posteEn": "Actor",
      "image": "jeremy",
      "flag": ["fr"],
      "audios": {
        "fr": [
          { "src": "../audios/jeremy/negocier.mp3", "title": "Narration - Négocier l'impossible - Livre audio" },
          { "src": "../audios/jeremy/jeu_video_mobile.mp3", "title": "Personnage Ralph - One State RP - Jeu vidéo mobile" },
          { "src": "../audios/jeremy/jojolapin.mp3", "title": "Voix cartoon Jojo Lapin - Médical - Motion Design" },
          { "src": "../audios/jeremy/LACASESBH.mp3", "title": "Voix principale - LA CASE SBH - Spot Radio" },
          { "src": "../audios/jeremy/LaPLANETEDESSINGES.mp3", "title": "Voix principale - La PLANETE DES SINGES - Spot Radio" },
          { "src": "../audios/jeremy/CourteSodaStreamCanada.mp3", "title": "Voix principale - SodaStream Canada - Spot TV version courte" },
          { "src": "../audios/jeremy/SodaStreamCanada.mp3", "title": "Voix principale - SodaStream Canada - Spot TV" },
          { "src": "../audios/jeremy/spotErima.mp3", "title": "Voix principale - Spot Erima - Publicité" },
          { "src": "../audios/jeremy/SpotLaSuite77.mp3", "title": "Voix principale - Spot LaSuite77 - Spot Radio" },
          { "src": "../audios/jeremy/SPOTPORCELANOSA.mp3", "title": "Voix principale - SPOT PORCELANOSA - Top Horaire" },
          { "src": "../audios/jeremy/SPOTWINAIR.mp3", "title": "Voix principale - SPOT WINAIR - Spot Radio" },
          { "src": "../audios/jeremy/Wauters.mp3", "title": "Voix principale - Wauters - Spot Radio" },
          { "src": "../audios/jeremy/TVSHLX.mp3", "title": "Voix principale accent africain - TVS HLX - Publicité internet" },
          { "src": "../audios/jeremy/VoixprincipaleaccentantillaisCOMPETBBQ.mp3", "title": "Voix principale accent antillais - COMPET BBQ - Spot Radio" },
          { "src": "../audios/jeremy/Medleydevoix-Demoreel.mp3", "title": "Medley de voix - Démo reel" },
          { "src": "../audios/jeremy/Narration-48eRencontreValdotaineaChampdepraz-VidéoYouTube.mp3", "title": "Narration - 48e Rencontre Valdotainea Champdepraz" },
          { "src": "../audios/jeremy/Narration-TIMTTrainingInnovationforMountainTransition-VidéoYouTube.mp3", "title": "Narration - TIMT Training Innovation for Mountain Transition " },
          { "src": "../audios/jeremy/Voiceover-LesRemplaceurs.mp3", "title": "Voice over - Les Remplaceurs de l'extreme ADAS France Pare-brise" }
        ],
        "en": [],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },    
    {
      nomPrenom: "Frédérique Foray",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "frederique",
      flag: ["fr"],
      audios: {
        fr: [
          { src: "../audios/fred/Centre Athénas - Documentaire.mp3", title: "Centre Athénas - Documentaire" },
          { src: "../audios/fred/Concert - Annonce .mp3", title: "Concert - Annonce" },
          { src: "../audios/fred/Demo Artisans du Changement - Documentaire.mp3", title: "Demo Artisans du Changement - Documentaire" },
          { src: "../audios/fred/Ouvre les Yeux Les algues-Bande-annonce.mp3", title: "Ouvre les Yeux Les Algues - Bande-annonce" },
          { src: "../audios/fred/Demo Le château de Versailles - Guide audio.mp3", title: "Demo Le château de Versailles - Guide audio" },
          { src: "../audios/fred/Demo Le château de Versailles 2 - Guide audio.mp3", title: "Demo Le château de Versailles 2 - Guide audio" },
          { src: "../audios/fred/Landerenis - attente telephonique.mp3", title: "Landerenis - Attente téléphonique" },
          { src: "../audios/fred/Meditation - Application Calm.mp3", title: "Méditation - Application Calm" },
          { src: "../audios/fred/Meditation 2 - Application Calm.mp3", title: "Méditation 2 - Application Calm" },
          { src: "../audios/fred/Demo Bjorg - Pub.mp3", title: "Demo Bjorg - Pub" },
          { src: "../audios/fred/Part_âge - Institutionel.mp3", title: "Part'âge - Institutionnel" },
          { src: "../audios/fred/Splintering Booking - Bande annonce.mp3", title: "Splintering Booking - Bande-annonce" }
        ],
        en: [],
        jp: []
      },
      iconsFr: [],
      iconsEn: []
    },
    {
      nomPrenom: "Chloé François (Tolémet)",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "chloe",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/chloe/Audiobook-100-Jours.mp3", title: "Elisa M.Poggio - 100 Jours De Nuit - Livre Audio (Audible)" },
          { src:"../audios/chloe/GFP-Enfant.mp3", title: "GFP (Enfant) - Opération K-K-WET - Fiction Audio" },
          { src:"../audios/chloe/Gus-Parmi-Eux.mp3", title: "Gus - Parmi Eux - Fiction Audio" },
          { src:"../audios/chloe/Demo-IVR-S.A.C-Studio.mp3", title: "IVR / SVI - S.A.C Studio" },
          { src:"../audios/chloe/Sacha-Vanille.mp3",title: "Sacha Vanille - Sascha - Fiction Audio" },
          { src:"../audios/chloe/Tommy-Pommette.mp3",title: "Tommy Pommette - TRAUMA FANTASY X  - Fiction Audio" },
          { src:"../audios/chloe/Miranda-Monster-Prom.mp3",title: "Miranda - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
          { src:"../audios/chloe/Valérie-Monster-Prom.mp3", title: "Valérie - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
          { src:"../audios/chloe/Joy-Monster-Prom.mp3",title: "Joy - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      "nomPrenom": "佩靈",
      "posteFr": "Comédienne",
      "posteEn": "Actress",
      "image": "佩靈",
      "flag": ["zk"],
      "audios": {
        "fr":[],
        "en": [],
        "jp": [],
        "zk": [
          { "src": "../audios/佩靈/Antarctica-Documentary.mp3", "title": "Antarctica Documentary" },
          { "src": "../audios/佩靈/DutchFlowerFarm-Trailer.mp3", "title": "Dutch Flower Farm - Trailer" },
          { "src": "../audios/佩靈/Essays-AD.mp3", "title": "Essays AD" },
          { "src": "../audios/佩靈/Eva-Perfectrecipe-Audiobook.mp3", "title": "Eva - Perfect recipe - Audiobook" },
          { "src": "../audios/佩靈/Fashionablewomen-AD.mp3", "title": "Fashionable women - AD" },
          { "src": "../audios/佩靈/Memoryofhome-AD.mp3", "title": "Memory of home - AD" },
          { "src": "../audios/佩靈/Jane-prideandPrejudice-Audiobook.mp3", "title": "Jane - prideand Prejudice - Audiobook" },
          { "src": "../audios/佩靈/Milan-Sunflowerinpocket-Bedtimestory-Audible.mp3", "title": "Milan - Sun flowerin pocket - Bed time story - Audible" },
          { "src": "../audios/佩靈/Mr.Terry-Eleaning.mp3", "title": "Mr.Terry - Eleaning" },
          { "src": "../audios/佩靈/WEE-AD.mp3", "title": "WEE - AD" },
          { "src": "../audios/佩靈/MrsBennetandMrcollins-prideandPrejudice-Audiobook.mp3", "title": "Mrs Bennetand Mr collins - pride and Prejudice - Audiobook" },
          { "src": "../audios/佩靈/MrsBennet-prideandPrejudice-Audiobook.mp3", "title": "Mrs Bennet - pride and Prejudice - Audiobook" },
          { "src": "../audios/佩靈/NorthAmericanMedicalInstitutionServices-Introduction.mp3", "title": "North American Medical Institution Services - Introduction" },
          { "src": "../audios/佩靈/Sarah-Littleprincess-Audiobook.mp3", "title": "Sarah - Little princess - Audiobook" },
          { "src": "../audios/佩靈/SeniorSales-Charactervoice-Audible.mp3", "title": "Senior Sales - Character voice - Audible" }
        ]
      },
      "iconsFr": [],
      "iconsEn": []
    },    
    {
      nomPrenom: "Jamal-deen (Ademola) Hamzat",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "jamal",
      flag: ["en","ng"],
      audios: {
        fr: [
        ],
        en: [
          { src:"../audios/jamal/ACEMoneyTransfer-DeliveringYourPromises-Commercials.mp3", title: "ACE Money Transfer - Delivering Your Promises - Commercials" },
          { src:"../audios/jamal/Africanaccent-GeneralDemoreel.mp3", title: "African accent - General Demo reel" },
          { src:"../audios/jamal/FederalGovernmentofNigeria-commonwealthgames-Trialer.mp3", title: "Federal Government of Nigeria - commonwealth games - Trialer" },
          { src:"../audios/jamal/PLANINTERNATIONAL-GENDERTRANSFORMATION-E-LEARNING.mp3", title: "Plan International - Gender transformation E-learning" },
          { src:"../audios/jamal/RIDA-Driverstep-by-step-Explainer.mp3", title: "RIDA - Driver step-by-step - Explainer" },
          { src:"../audios/jamal/UNDP-Animation-SeriesEnergy-Narration.mp3", title: "UNDP - Animation - Series Energy - Narration" },
          { src:"../audios/jamal/Supersport-Sportybet-Promo.mp3", title: "Supersport - Sportybet - Promo" },
          { src:"../audios/jamal/Yango-Radio-Commercial.mp3", title: "Yango - Radio - Commercial" },
          { src:"../audios/jamal/ZUWUTU-OFFICEPROMPT-IVR.mp3", title: "ZUWUTU - OFFICE PROMPT - IVR" },
        ],
        ng:[],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Cécile Heredia",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "cecile",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/cecile/BillboardTV-Milka-Publicit.mp3", title: "Billboard TV - Milka - Publicité" },
          { src:"../audios/cecile/Explainer-Showreel-e-learning.mp3", title: "Explainer - Showreel - E-learning" },
          { src:"../audios/cecile/Felicia-PAPERPORT-dessinanime.mp3", title: "Felicia - PAPER PORT - Dessin animé" },
          { src:"../audios/cecile/Narration-documentairescientifique.mp3", title: "Narration - Documentaire scientifique" },
          { src:"../audios/cecile/Narratrice-Manouvellevielaplage-Documentaire.mp3", title: "Narratrice - Ma nouvelle vie la plage - Documentaire" },
          { src:"../audios/cecile/Personnages-showreel-voixcartoon.mp3", title: "Personnages - showreel - Voix cartoon" },
          { src:"../audios/cecile/Publicite-showreel-diffusionmagasin.mp3", title: "Publicité - showreel - Diffusion magasin" },
          { src:"../audios/cecile/Publicite-showreel-interpretationsjouees.mp3", title: "Publicité - showreel - Interprétations jouées" },
          { src:"../audios/cecile/Voiceover-Interpretationnaturelle.mp3", title: "Voice over - Interprétation naturelle" },
          { src:"../audios/cecile/VoixAntenne-Showreel-habillagesradio.mp3", title: "Voix Antenne - Showreel - habillages radio" },
          { src:"../audios/cecile/Voixoff-interpretationinspirationnelle.mp3", title: "Voix Off - Interprétation inspirationnelle" },
          { src:"../audios/cecile/Voixoff-InterpretationMarrantepositive.mp3", title: "Voix Off - Interprétation Marrante positive" },
          { src:"../audios/cecile/Voixoff-Interpretationcorporate.mp3", title: "Voix off - Interprétation corporate" },
          { src:"../audios/cecile/VoixOff-InterpretationDetendue.mp3", title: "Voix Off - Interprétation détendue" },
          { src:"../audios/cecile/JournaldeFrance-publiciteinstitutionnelle-interpretationenergique.mp3", title: "Journal de France - Publicité institutionnelle - Interprétation énergique" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      "nomPrenom": "Alexandra Lin",
      "posteFr": "Comédienne",
      "posteEn": "Actress",
      "image": "alexandra",
      "flag": ["fr"],
      "audios": {
        "fr": [
          { "src": "../audios/alexandra/AUDIOBOOK.mp3", "title": "Audiobook" },
          { "src": "../audios/alexandra/AVIGNON-_-S.BELVISI.mp3", "title": "Avignon - S.Belvisi" },
          { "src": "../audios/alexandra/BATV.mp3", "title": "BA TV Nouveau" },
          { "src": "../audios/alexandra/BUDDHA-BAR-25.mp3", "title": "Buddha Bar 25" },
          { "src": "../audios/alexandra/DANSAVION.mp3", "title": "Dans l'Avion" },
          { "src": "../audios/alexandra/Entreprendre.wapi.mp3", "title": "Entreprendre Wapi" },
          { "src": "../audios/alexandra/extraitemission.mp3", "title": "Extrait Émission" },
          { "src": "../audios/alexandra/Le-Grenier-du-Rire.mp3", "title": "Le Grenier du Rire" },
          { "src": "../audios/alexandra/MCMEDIAX.mp3", "title": "MC Mediax UK3" },
          { "src": "../audios/alexandra/Medleycartoons.mp3", "title": "Medley Cartoons" },
          { "src": "../audios/alexandra/MOTIONDESIGNMAVOIXPOURKWSFRANCE.mp3", "title": "Motion Design - KWS France" },
          { "src": "../audios/alexandra/OctobreRoseAudio.mp3", "title": "Octobre Rose" },
          { "src": "../audios/alexandra/PStorytelling.mp3", "title": "Polome Bijoux - Storytelling" },
          { "src": "../audios/alexandra/PROMO-MONTREIL-COMEDY-CLUB-JEUDI-19-octobrewav.mp3", "title": "Promo Montreuil Comedy Club" },
          { "src": "../audios/alexandra/SOLIDART.mp3", "title": "Solid Art Radio FG" },
          { "src": "../audios/alexandra/VoixvaisseauSkytale.mp3", "title": "Voix Vaisseau Skytale" },
          { "src": "../audios/alexandra/la_legende_de_notre-dame.mp3", "title": "La légende de Notre Dame"}
        ],
        "en": [],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },
    {
      nomPrenom: "Sylvain Maury",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "sylvain",
      flag: ["fr"],
      audios: {
        fr: [
          { src: "../audios/sylvain/AlanKay.mp3", title: "Alan Kay - Alone les survivants - TV réalité" },
          { src: "../audios/sylvain/CamionnetteProfil.mp3", title: "Camionnette Profil + - Profil + - Pub TV" },
          { src: "../audios/sylvain/ChanteurPresentation.mp3", title: "Chanteur - Bonjour ! - Présentation chantée" },
          { src: "../audios/sylvain/CharlemagneProduits814.mp3", title: "Charlemagne agé - Produits 814 - Podcast" },
          { src: "../audios/sylvain/Denton-KingsCasino-Doublage.mp3", title: "Denton - Kings Casino - Doublage" },
          { src: "../audios/sylvain/LeeMurray-LeCoutduRique-Documentaire.mp3", title: "Lee Murray - Le Cout du Rique - Documentaire TV" },
          { src: "../audios/sylvain/Narrateur-LeFagace-Institutionnelle.mp3", title: "Narrateur - Le Fagace - Vidéo Institutionnelle" },
          { src: "../audios/sylvain/Narrateur-Muséed_Ephèse-Audioguide.mp3", title: "Narrateur - Musée d'Ephèse - Audioguide" },
          { src: "../audios/sylvain/RoggetAvon-HistoireAlpine-Dessinanimé.mp3", title: "Rogg et Avon - Histoire Alpine - Dessin animé" },
          { src: "../audios/sylvain/VoixAntenne-SunOuest-Habillageradio.mp3", title: "Voix Antenne - Sun Ouest - Habillage radio" },
          { src: "../audios/sylvain/VoixOff-AviraVPN-PubTV.mp3", title: "Voix Off - Avira VPN - Pub TV" },
          { src: "../audios/sylvain/VoixOff-Quick-PubTV.mp3", title: "Voix Off - Quick - Pub TV" },
          { src: "../audios/sylvain/VoixOff-HarryPotterExposition-PubTV.mp3", title: "Voix Off - Harry Potter Exposition - Pub TV" },
          { src: "../audios/sylvain/VoixoffRicketMorty-BandeAnnonce.mp3", title: "Voix off - Rick et Morty - Bande Annonce" },
          { src: "../audios/sylvain/Voixoff-TheGreenstore-BillboardTV.mp3", title: "Voix off - The Greenstore - Billboard TV" },
          { src: "../audios/sylvain/VoixOffChant-Mikit-BillboardTV.mp3", title: "Voix Off + Chant - Mikit - Billboard TV" },
          { src: "../audios/sylvain/VoixOff-Tone-BillboardTV.mp3", title: "Voix Off - Tone - Billboard TV" }
        ],
        en: [],
        jp: []
      },
      iconsFr: []
    },   
    {
      nomPrenom: "Mathieu Moreau",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "mathieu",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/mathieu/Zabuza_Momochi.mp3", title: "Zabuza Momochi - Naruto - Animé" },
          { src:"../audios/mathieu/Baggy.mp3", title: "Baggy Le Clown - One Piece - Animé" },
          { src:"../audios/mathieu/Kanan-Star-Wars-Rebels-Saison1-Séries-danimation.mp3",title: "Kanan - Star Wars Rebels Saison 1 - Séries d'animation" },
          { src:"../audios/mathieu/Albator.mp3", title: "Albator Corsaire de l'espace - Film d'animation" },
          { src:"../audios/mathieu/Leon_Kennedy.mp3",title: "Leon S. Kennedy - Resident Evil Degeneration - Film d'animation" },
          { src:"../audios/mathieu/Qui.mp3",title: "Qui peut sauver le Far West ? - Abahachi Winnetouch - Film" },
          { src:"../audios/mathieu/Pokémon.mp3",title: "Pokémon - Bande-annonce animée (Nintendo DS)" },
          { src:"../audios/mathieu/Yamikai.mp3", title: "Yamikai - Le Monde Obscur - Bande Annonce Manga" },
          { src:"../audios/mathieu/Maraad.mp3",title: "Maraad - World of Warcraft Warlords of Draenor - Jeux vidéo" },
          { src:"../audios/mathieu/Le_directeur_Salges_Épisode_1.mp3", title: "Le directeur Salges - Fond de Cale - Épisode 1 - Fiction Audio (2023)" },
          { src:"../audios/mathieu/Le_directeur_Salges_Épisode_2.mp3",title: "Le directeur Salges - Fond de Cale - Épisode 2 - Fiction Audio (2024)" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      "nomPrenom": "Damien Locqueneux",
      "posteFr": "Comédien",
      "posteEn": "Actor",
      "image": "damien",
      "flag": ["fr"],
      "audios": {
        "fr": [
          { "src": "/audios/damien/101.mp3", "title": "101 - La réanimation est un enjeu - Voix off web" },
          { "src": "/audios/damien/Alfonso.mp3", "title": "Alfonso - Les explorateurs - Doublage film animé" },
          { "src": "/audios/damien/Comment.mp3", "title": "Comment ça pousse - France 5 et RTS - Voix off TV et web" },
          { "src": "/audios/damien/Freddy-100_-loup-Clip-chant-film.mp3", "title": "Freddy - 100% loup - Clip chant film" },
          { "src": "/audios/damien/Les-Sherpas-Voix-off-Pub-TV.mp3", "title": "Les Sherpas - Voix off Pub TV" },
          { "src": "/audios/damien/SNCB-Voix-off-interne-corporate.mp3", "title": "SNCB - Voix off interne corporate" },
          { "src": "/audios/damien/Logre.mp3", "title": "L'ogre - Prince Ivandoe - Doublage série animée" },
          { "src": "/audios/damien/Max-Max-Steel-Doublage-film-live.mp3", "title": "Max - Max Steel - Doublage film live" },
          { "src": "/audios/damien/Richie-It_s-a-sin-Doublage-serie-live.mp3", "title": "Richie - It's a sin - Doublage série live" }
        ],
        "en": [],
        "jp": []
      },
      "iconsFr": [],
      "iconsEn": []
    },    
    {
      nomPrenom: "Noboru Okamoto",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "noboru",
      flag: ["jp"],
      audios: {
        jp: [
          { src:"../audios/noboru/岡本昇 SNSバンパー広告.mp3", title: "岡本昇 SNSバンパー広告" },
          { src:"../audios/noboru/岡本昇 TV番組 女子高生ミスコン.mp3", title: "岡本昇 TV番組 女子高生ミスコン" },
          { src:"../audios/noboru/岡本昇 かけがえのない人 映画トレーラー.mp3", title: "岡本昇 かけがえのない人 映画トレーラー" },
          { src:"../audios/noboru/岡本昇 映画ゾンビスクール 映画トレーラー.mp3", title: "岡本昇 映画ゾンビスクール 映画トレーラー" },
          { src:"../audios/noboru/岡本昇 スポーツ等 煽り.mp3", title: "岡本昇 スポーツ等 煽り" },
          { src:"../audios/noboru/岡本昇 セリフ ダイジェスト.mp3", title: "岡本昇 セリフ ダイジェスト" },
          { src:"../audios/noboru/岡本昇 マニュアル.mp3", title: "岡本昇 マニュアル" },
          { src:"../audios/noboru/岡本昇 介護 親しみ、信頼、清潔.mp3", title: "岡本昇 介護 親しみ、信頼、清潔" },
          { src:"../audios/noboru/岡本昇 企業VP 低音.mp3", title: "岡本昇 企業VP 低音" },
          { src:"../audios/noboru/岡本昇 企業VP 落ち着き.mp3", title: "岡本昇 企業VP 落ち着き" },
          { src:"../audios/noboru/岡本昇 電気アプリCM 明るい訴求.mp3", title: "岡本昇 電気アプリCM 明るい訴求" },

        ],
        fr:[],
        en: [
        ]
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Sara Pereira",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "sara",
      flag: ["fr","en","es","pt"],
      audios: {
        fr: [
          { src: "../audios/sara/Airton - inspirationnel chaleureux posé - Publicité Web.mp3", title: "Airton - Inspirationnel chaleureux posé - Publicité Web" },
          { src: "../audios/sara/Billboard - Le Parfait - Frais souriant - Publicité TV.mp3", title: "Billboard - Le Parfait - Frais souriant - Publicité TV" },
          { src: "../audios/sara/Lego - souriant complice - Pub Web.mp3", title: "Lego - Souriant, complice - Publicité Web" },
          { src: "../audios/sara/CCFD - positif émotion - Publicité radio.mp3", title: "CCFD - Positif émotion - Publicité Radio" },
          { src: "../audios/sara/Création de personnages - fille garçon adolescente dame âgée créature sorcière bourgeoise.mp3", title: "Création de personnages - Divers types de voix" },
          { src: "../audios/sara/Ecureuil voix enfant - Space X intellectokids -jeu vidéo éducatif.mp3", title: "Écureuil (voix enfant) - Space X Intellectokids - Jeu vidéo éducatif" },
          { src: "../audios/sara/Institutionnel - Medley Vidéos corporate Motion Design.mp3", title: "Institutionnel - Medley Vidéos corporate et Motion Design" },
          { src: "../audios/sara/Jeanne d_Arc voix jeune - Victory Belles - jeu vidéo.mp3", title: "Jeanne d'Arc - Voix jeune - Victory Belles - Jeu vidéo" },
          { src: "../audios/sara/La couleur des émotions - Démo Livre audio Jeunesse.mp3", title: "La couleur des émotions - Livre Audio Jeunesse" },
          { src: "../audios/sara/La légende de Drizzt Tome 3 - Démo Livre audio Fiction.mp3", title: "La légende de Drizzt - Tome 3 - Livre Audio Fiction" },
          { src: "../audios/sara/Le pouvoir du moment présent - Démo Livre audio développement personnel.mp3", title: "Le pouvoir du moment présent - Livre Audio Développement Personnel" },
          { src: "../audios/sara/Campagne Nationale HCE -signature émotion - Publicité TV Radio Web.mp3", title: "Campagne Nationale HCE - Signature émotion - Publicité TV Radio Web" },
          { src: "../audios/sara/Tracer - jeune enthousiaste - Démo jeu vidéo Overwatch.mp3", title: "Tracer - Jeune enthousiaste - Doublage jeu vidéo Overwatch" },
          { src: "../audios/sara/Maformationfr - complice souriant énergique - Publicité TV.mp3", title: "Maformation.fr - Complice, souriant, énergique - Publicité TV" },
          { src: "../audios/sara/Moignon - Kaeloo - Démo doublage animation.mp3", title: "Moignon - Kaeloo - Démo doublage animation" },
          { src: "../audios/sara/Voice Over - Medley voix naturelle.mp3", title: "Voice Over - Medley voix naturelle" },
          { src: "../audios/sara/Pourquoi l_amour fait mal - Démo Livre audio Sociologie.mp3", title: "Pourquoi l'amour fait mal - Livre Audio Sociologie" },
          { src: "../audios/sara/Sabine B - attente téléphonique en Français et Anglais.mp3", title: "Sabine B - Attente téléphonique (Français et Anglais)" },
          { src: "../audios/sara/Samsung - déterminée souriante - Publicité Spotify.mp3", title: "Samsung - Déterminée, souriante - Publicité Spotify" },
          { src: "../audios/sara/SeLoger - naturel, souriant - Publicité Web.mp3", title: "SeLoger - Naturel, souriant - Publicité Web" },
          { src: "../audios/sara/Sharon et Oliver voix femme garçon - Réunis pour Noël - Doublage fiction.mp3", title: "Sharon et Oliver - Voix femme et garçon - Réunis pour Noël - Doublage Fiction" },
          { src: "../audios/sara/Lily personnage adolescente caricaturale -Duolinguo - application.mp3", title: "Lily - Personnage adolescente caricaturale - Duolinguo - Application" },
          { src: "../audios/sara/Narratrice - Medley E-learning.mp3", title: "Narratrice - Medley E-learning" }
        ],
        en: [
          { src: "../audios/sara/Nowos - Motion design - Anglais.mp3", title: "Nowos - Motion Design" },
          { src: "../audios/sara/Safecube -Motion design - Anglais.mp3", title: "Safecube - Motion Design" }
        ],
        es: [
          { src: "../audios/sara/Coca Cola - Pub podcast - Espagnol.mp3", title: "Coca Cola - Publicité Podcast" },
          { src: "../audios/sara/OTM Tax - E-learning - Espagnol.mp3", title: "OTM Tax - E-learning" }
        ],
        pt: [
          { src: "../audios/sara/OTM Tax - E-learning - Portugais.mp3", title: "OTM Tax - E-learning" },
          { src: "../audios/sara/Quercus - Publicité en Portugais.mp3", title: "Quercus - Publicité" }
        ]
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Arthur Pouillès-Duplaix (Wolf)",
      posteFr: "Comédien & Gestion de projet Youtube ",
      posteEn: "Actor & YouTube Project Management",
      image: "arthur",
      flag: ["fr", "en"],
      audios: {
        fr: [
          { src:"../audios/arthur/8eSortilège_Pratchett.mp3", title: "Terry Pratchett - Le Huitième Sortilège - Livre Audio" },
          { src:"../audios/arthur/DesHommesSansFemme_Murakami.mp3", title: "Haruki Murakami - Des Hommes Sans Femme - Livres Audio" },
          { src:"../audios/arthur/ArthurPouillesduplaix_IVR_Demo.mp3", title: "IVR / SVI - S.A.C Studio" },
          { src:"../audios/arthur/Florian-Sascha.mp3", title: "Florian - Sascha - Fiction Audio" },
          { src:"../audios/arthur/1287-LordMoustache.mp3", title: "Lord Moustache - 1287 - Fiction Audio" },
          { src:"../audios/arthur/Doom_Man-D_Day.mp3", title: "Doom Man - D Day - Fiction Audio" },
          { src:"../audios/arthur/LesPourfendeurs-Huggy.mp3", title: "Huggy - Les Pourfendeurs  - Fiction Audio" },
          { src:"../audios/arthur/Moignon_Robert_Groin.mp3", title: "Moignon - Robert Groin - Fiction Audio" },
          { src:"../audios/arthur/Policiers-Café-_-Lembas.mp3", title: "Café & Lembas - Policiers - Fiction Audio" },
          { src:"../audios/arthur/Narration-Forces-Mentales.mp3", title: "Narration - Forces Mentales - Fiction Audio" },
          { src:"../audios/arthur/Narration-The-Blind-Zone.mp3", title: "Narration - The Blind Zone - Fiction Audio" },
          { src:"../audios/arthur/Peter-Syriv-Jencyo-Rêva.mp3", title: "Peter Syriv - Jencyo Rêva - Fiction Audio" },
          { src:"../audios/arthur/Philippe-du-Petipu-_Les-Résidents-et-Vils-Pouilleux_-_1_.mp3", title: "Philippe du Petipu - Les Résidents et Vils Pouilleux - Fiction Audio" },
          { src:"../audios/arthur/MonsterProm_Damien.mp3", title: "Damien - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },

        ],
        en: [
          { src:"../audios/arthur/IVR_English.mp3", title: "IVR / SVI - S.A.C Studio" },
        ],
        jp: []
      },
      iconsFr: [
      ],
      iconsEn: [
      ],
    },
    {
      nomPrenom: "Claire Pradalié",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "claire",
      flag: ["fr","en"],
      audios: {
        fr: [
          { src:"../audios/claire/Abeille-Chromebook-Pub.mp3", title: "Abeille - Chromebook - Pub" },
          { src:"../audios/claire/Atelier-Chocolat-Pub.mp3", title: "Atelier - Chocolat - Pub" },
          { src:"../audios/claire/Antipuces-Frontlinecombo-pub.mp3", title: "Antipuces - Frontline combo - Pub" },
          { src:"../audios/claire/BACKTOSCHOOL-HPInstantInk-Pub.mp3", title: "BACK TO SCHOOL - HP Instant Ink - Pub" },
          { src:"../audios/claire/Aureliane-Lespoirsurlerivage-Livreaudio(Audible).mp3", title: "Aureliane - L'espoir sur le rivage - Livre audio (Audible)" },
          { src:"../audios/claire/DrunkonLove-livreaudio(Audible).wav", title: "Drunk on Love - Livre audio (Audible)" },
          { src:"../audios/claire/Kassie-Liaisons.com-Livreaudio(Audible).mp3", title: "Kassie-Liaisons.com - Livre audio (Audible)" },
          { src:"../audios/claire/Doudous-Lemondemerveilleuxdesdoudous-livreaudio.mp3", title: "Doudous - Lemonde merveilleux des doudous - Livre audio" },
          { src:"../audios/claire/Anzu-Suzuran-Livreaudio.mp3", title: "Anzu - Suzuran - Livre audio" },
          { src:"../audios/claire/Eléphants-Babar-Libreaudio.mp3", title: "Eléphants - Babar - Livre audio" },
          { src:"../audios/claire/Lespoules-Lapetitepoulequivoulaitvoirelamer-Livreaudio.mp3", title: "Les poules - La petite poule qui voulait voire la mer - Livre audio" },
          { src:"../audios/claire/Lalicorne-Histoirepourdormir-méditation.mp3", title: "La licorne - Histoire pour dormir - Méditation" },
          { src:"../audios/claire/Visualisation-méditation.mp3", title: "Visualisation - Méditation" },
          { src:"../audios/claire/Carnetdebal-Docu.mp3", title: "Carnet de bal - Docu" },
          { src:"../audios/claire/Friginox-Institutionnel.mp3", title: "Friginox - Institutionnel" },
          { src:"../audios/claire/Infirmière-podcast.mp3", title: "Infirmière - Podcast" },
          { src:"../audios/claire/Méo-Spotify-Pub.mp3", title: "Méo - Spotify - Pub" },
          { src:"../audios/claire/Pokemon-jeuenfant-Pub.mp3", title: "Pokemon - jeu enfant - Pub" },
          { src:"../audios/claire/roquefort-Fromage-Pub.mp3", title: "Roquefort - fromage - Pub" },
          { src:"../audios/claire/Spectrum-Institutionnel.mp3", title: "Spectrum - Institutionnel" },
        
        ],
        en: [
          { src:"../audios/claire/CaféGateauParfumLingerie-anglaisavecaccentfrançais-Pub.mp3", title: "Café Gateau Parfum Lingerie - anglais avec accent français - Pub" },

        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Vanessa Réty",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "vanessa",
      flag: ["fr"],
      audios: {
        fr: [
          { src: "../audios/vanessa/Jeunesportive–CéréalesLion2024–Publicitéweb.mp3", title: "Jeune sportive – Céréales Lion 2024 – Publicité web" },
          { src: "../audios/vanessa/NarratriceSARLTisserandJacques.mp3", title: "Narratrice - SARL Tisserand & Jacques - Publicité cinéma" },
          { src: "../audios/vanessa/Medleypublicitéradio.mp3", title: "Medley publicité radio" },
          { src: "../audios/vanessa/PersonnagesPublicitésradio.mp3", title: "Personnages - Publicités radio" },
          { src: "../audios/vanessa/SorcièreChaussexpo-Publicitéindoor.mp3", title: "Sorcière - Chaussexpo - Publicité indoor" },
          { src: "../audios/vanessa/NarratriceLaChaufferieBillboard.mp3", title: "Narratrice - La Chaufferie - Billboard TV" },
          { src: "../audios/vanessa/Narratrice-Faufile-toi.mp3", title: "Narratrice - 1,2,3 Faufile-toi (Ravensburger) - Teaser web" },
          { src: "../audios/vanessa/Medleyhabillageradio.mp3", title: "Medley habillage radio" },
          { src: "../audios/vanessa/NarratriceDocumentaire.mp3", title: "Narratrice - Documentaire" },
          { src: "../audios/vanessa/Medleymotiondesign.mp3", title: "Medley motion design" },
          { src: "../audios/vanessa/Medleye-learning.mp3", title: "Medley e-learning" },
          { src: "../audios/vanessa/Susan-E-learning.mp3", title: "Susan - E-learning - Chant" },
          { src: "../audios/vanessa/NarratriceMeldomysInstitutionnel.mp3", title: "Narratrice - Meldomys - Institutionnel" },
          { src: "../audios/vanessa/LauraStone-RéussirdansleDevOps-Voiceover.mp3", title: "Laura Stone - Réussir dans le DevOps - Voiceover" },
          { src: "../audios/vanessa/Medleylivreaudio.mp3", title: "Medley livre audio" },
          { src: "../audios/vanessa/Medleyaudioguide.mp3", title: "Medley audioguide" },
          { src: "../audios/vanessa/StandaristeRestPetitBonheur.mp3", title: "Standardiste - Rest'au Petit Bonheur - Attente téléphonique" }
        ],
        en: [],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      nomPrenom: "Thibault Rispal (DestroKhorne)",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "thibault",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/thibault/A_chaud-Sliders-chapitre_03.mp3", title: "A chaud - Sliders chapitre 03 - Livre Audio" },
          { src:"../audios/thibault/231Germaine-OHara.mp3", title: "Ohara - 231Germaine - Fiction Audio" },
          { src:"../audios/thibault/1287-Mamie petouse.mp3", title: "Mamie petouse - 1287 - Fiction Audio" },
          { src:"../audios/thibault/La_Descente_Rick_Hensen.mp3", title: "Rick Hensen - La Descente - Fiction Audio" },
          { src:"../audios/thibault/Le-Chasseur-Orion.mp3", title: "Le Chasseur - Orion - Fiction Audio" },
          { src:"../audios/thibault/CINQ-Martin.mp3", title: "Martin - CINQ - Fiction Audio" },
          { src:"../audios/thibault/Les résidents et vils pouilleux - Franklin et le portier.mp3", title: "Franklin & Le portier - Les Résidents et vils pouilleux - Fiction Audio" }
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      nomPrenom: "Aubrey Tyson",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "aubrey",
      flag: ["en"],
      audios: {
        fr: [
        ],
        en: [
          { src:"../audios/aubrey/AubreyTyson-CorporateNarrationV1.mp3", title: "Corporate Narration V1" },
          { src:"../audios/aubrey/Caring&Warm-Jessica-PSACommercial.mp3", title: "Caring & Warm - Jessica - PSA Commercial" },
          { src:"../audios/aubrey/CasualandCool-TheRealReal-Commercial.mp3", title: "Casual and Cool - The Real Real - Commercial" },
          { src:"../audios/aubrey/ConversationalAdult-DollarBank-Commercial.mp3", title: "Conversational Adult - Dollar Bank - Commercial" },
          { src:"../audios/aubrey/Friendly&Welcoming-ShredNations-OnHoldMessage.mp3", title: "Friendly & Welcoming - Shred Nations - On Hold Message" },
          { src:"../audios/aubrey/Fun&Lighthearted-MercedesBenzBarkAssist-Commercial.mp3", title: "Fun & Lighthearted - Mercedes Benz Bark Assist - Commercial" },
          { src:"../audios/aubrey/MillennialMom-Telluride-Commercial.mp3", title: "Millennial Mom - Telluride - Commercial" },
          { src:"../audios/aubrey/Narrator-FamilyServices-Commercial.mp3", title: "Narrator - Family Services - Commercial" },
          { src:"../audios/aubrey/Narrator-FamilyServices-IVR.mp3", title: "Narrator - Family Services - IVR" },
          { src:"../audios/aubrey/Narrator-iFlexProject-Explainer.mp3", title: "Narrator - iFlex Project - Explainer" },
          { src:"../audios/aubrey/Narrator-MeetRandy-Presentation.mp3", title: "Narrator - Meet Randy - Presentation" },
          { src:"../audios/aubrey/Narrator-NewMobileApp-Explainer.mp3", title: "Narrator - New Mobile App - Explainer" },
          { src:"../audios/aubrey/Narrator-OVPathForward-WebVideo.mp3", title: "Narrator - OV's Path Forward - Web Video" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },


    {
      nomPrenom: "Alice Bracco",
      posteFr: "Community Manager",
      posteEn: "Community Manager",
      image: "alice",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },
  
      iconsFr: [
        {
          icon: <Instagram width={20} height={20} />,
          lien: "https://www.instagram.com/comalice.cm?igsh=ZWhhaGF2ZHV5ZGV2",
        },
      ],
    },
    {
      nomPrenom: "Basile Garoufalakis (BAZ)",
      posteFr: "Graphiste Illustrateur",
      posteEn: "Graphic designer & illustrator",
      image: "basile",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },  
      iconsFr: [
        {
          icon: <Instagram width={20} height={20} />,
          lien: "https://www.instagram.com/bazgraphite/",
        },
      ],
    },
    {
      nomPrenom: "Romanne Aguenier-Pailler",
      posteFr: "Rédactrice & Correctrice",
      posteEn: "Copywriter",
      image: "romanne",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },  
      iconsFr: [
        {
          icon: <Linkedin width={20} height={20} />,
          lien: "https://www.linkedin.com/in/romanne-pailler-1b35092bb/",
        },
      ],
    },
  ],
  pricingCard: [
    {
      titre: "IVR / SVI",
      texte:
        "Bénéficiez de messages vocaux sur mesure, enregistrés par des comédiens professionnels pour guider et informer efficacement vos clients et vos contacts grâce à nos voix pour votre serveur vocal interactif.",
      price: 100,
    },
    {
      titre: "Livre audio",
      texte:
        "Transformez vos écrits en expériences auditives immersives. Nos voix professionnelles narrent vos histoires, offrant à votre audience une nouvelle manière de se connecter à votre contenu.",
      price: 350,
    },
    {
      titre: "Spot publicitaire",
      texte:
        "Captivez votre audience dès les premières secondes avec un spot publicitaire impactant. Nos talents vocaux professionnels donnent vie à votre message, garantissant un impact maximal auprès de vos cibles.",
      price: 500,
    },
    {
      titre: "Fiction Audio (Saga Audio)",
      texte:
        "Plongez vos auditeurs dans des mondes captivants avec nos sagas audio haut de gamme. Nous créons des univers sonores riches et des narrations dynamiques, interprétées par des comédiens de talent, pour une expérience d'écoute inoubliable.",
      price: 5000,
    },
    {
      titre: "Audio de relaxation",
      texte:
        "Nous créons des enregistrements audio relaxants pour favoriser la détente et le bien-être, adaptés à divers environnements tels que les hôpitaux, les spas et les centres de bien-être, ou simplement pour un usage personnel.",
      price: 2500,
      info: "-20% pour les établissements publics",
    },
  ],
  reviews: [
    {
      nomPrenom: "Raoul Miclo",
      poste: "Lead Animator",
      entreprise: "PLAYSTUDIO",
      langKey: "raoul",
      review:
        "Ricardo, connu sous le nom d'Akira, est un acteur talentueux capable de jouer divers rôles avec aisance. Il est également à l'origine d'une plateforme de diffusion majeure pour la série et excelle en tant que responsable commercial, maîtrisant le développement et la communication de projets marketing. Je le recommande vivement à toute personne.",
      image: "raoul",
      stars: {
        count: 4,
        max: 5,
      },
    },
    {
      nomPrenom: "Matthieu Papazoglakis",
      langKey: "matthieu",
      review:
        "Le studio S.A.C. est le coup de pouce qui m'a lancé. C'était l'opportunité de travailler avec des pros dans des conditions raisonnables, faire des rencontres inoubliables et surtout passer d'excellents moments tout en restant à échelle humaine : On trouve ensemble les solutions aux problèmes qui peuvent survenir tout en restant dans un cadre super convivial !",
      image: "mathieu",
      stars: {
        count: 5,
        max: 5,
      },
    },
    {
      nomPrenom: "Grégory de Leew",
      poste: "Photographe et vidéaste",
      entreprise: "free-lance",
      langKey: "gregory",
      review:
        "Plusieurs années que nous travaillons ensemble, notamment sur le projet de chaine Youtube. J'ai donc un avis très positif sur la qualité et le rendu global pour S.A.C studio. Faites leurs confiance les yeux fermés pour vos projets, vous serez parfaitement guidé.",
      image: "gregory",
      stars: {
        count: 5,
        max: 5,
      },
    },
  ],
};


export { config };
